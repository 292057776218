/*==========================================================
=  Import & reuse bootstrap,FontAwesome functions, mixins,
variables =
==========================================================*/

@import "bootstrap/functions";
@import "custom/bootstrap-variables";
@import "bootstrap/mixins";

@import "fontawesome/variables";
@import "fontawesome/mixins";

@import "custom/variables";
@import "custom/base";
/*==============================
=            Header            =
==============================*/

@import "custom/font";
@import "custom/header";

/*=====  End of Header  ======*/

/*==============================
=            Layout            =
==============================*/

@import "custom/layout";

/*=====  End of Layout  ======*/

/*==============================
=            Post            =
==============================*/

@import "custom/post/post-page-category";

/*=====  End of Post  ======*/

/*==============================
=            Widget            =
==============================*/

@import "custom/widget/widget-base";
@import "custom/widget/widget_cc_accordion";
@import "custom/widget/widget_cc_post_featured";
@import "custom/widget/widget_cc_photo_gallery";
@import "custom/widget/widget_cc_post_grid";
@import "custom/widget/widget_cc_video_grid";

/*=====  End of Widget  ======*/

/*==============================
=            Footer            =
==============================*/

@import "custom/footer";

/*=====  End of Footer  ======*/
