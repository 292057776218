img {
  max-width: 100%;
  height: auto;
}
.page-heading {
    background-color: #f7f7f7;
    padding: 15px 0;
    margin-bottom: 2rem;
    h1 {
    	font-size: 2rem;
    }
}
.btn {
	cursor: pointer;
}