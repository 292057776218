.home {
	.site-main {
		.widget {
			padding: 1rem 0;
		}
		.widget-title {
			font-weight: bold;
			text-align: center;
			font-size: 14px;
			@include media-breakpoint-up(lg) {
				font-size: 20px;
			}
			text-transform: uppercase;
		    margin-bottom: 0.65rem;
		    color: #333;
		    span {
		    	&:before, &:after {
		    		content: '';
		    		width: 40px;
	    			height: 5px;
		    		@include media-breakpoint-up(lg) {
			    		width: 52px;
		    			height: 7px;
			    	}
		    		background-image: url('data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAHAgMAAAA6+IZ7AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACVBMVEX////7Xhz///+cVv8YAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAHdElNRQfhDBIQHAY9HkuKAAAAEUlEQVQI12MIRQYM1AEoZgIAp3MIoxTXfTMAAAAASUVORK5CYII=');
		    		background-size: contain;
		    		display: inline-block;
		    		vertical-align: middle;
		    		
		    	}
		    	&:before {
		    		margin-right: 1rem;
		    	}
		    	&:after {
		    		margin-left: 1rem;
		    	}
		    }
		}
		.widget-desc {
			color: #959595;
			text-align: center;
			margin-bottom: 0.75rem;
		}
	}
}

.widget-area {
	.widget {
		margin-bottom: 1em;
		.widget-title {
			color: $widget_title_color;
		    margin-bottom: 0;
		    font-size: 1.2rem;
		    text-transform: uppercase;
		    padding: 0.625rem 0;
			border-bottom: $widget_title_bg_border;
		}
	}
}