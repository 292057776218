.cc_video_widget {
	background-color: #f7f7f7;
	.lazy {
		.play {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			width: 70px;
			height: 70px;
			cursor: pointer;
			border: 0;
			outline: 0;
			background-color: rgba(#161616, 0.7);
			i {
				color: #fff;
				font-size: 2rem;
			}
		}
	}
}