.cc_accordion_widget {
	padding: 1rem ;
	@include media-breakpoint-up(lg) {
		.accordion-title {
			border-right: 3px solid $primary_color;
		}
	}
	.accordion-desc {
		font-size: 14px;
	}
	.accordion {
		.accordion-item {
			margin-bottom: 0.5rem;
			.item-header {
				background-color: #161616;
				a {
					color: #fff;
					display: block;
					font-size: 14px;
					padding: 0.75rem 1rem;
				}
			}
			.item-body {
				color: #858585;
				padding: 0.25rem;
			}
		}
	}
	.lazy {
		.play {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			width: 70px;
			height: 70px;
			cursor: pointer;
			border: 0;
			outline: 0;
			background-color: rgba(#161616, 0.7);
			i {
				color: $primary_color;
				font-size: 2rem;
			}
		}
	}
}
