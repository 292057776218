.site-header {
	position: sticky;
    top: 0;
    z-index: 1020;
    background-color: $header_nav_bg;
    box-shadow: 0 1px 1px rgba($gray-500, 0.35);
    border-bottom: 1px solid $gray-100;
    &.header-fixed {
    	.header-nav-search {
    		display: none;
    	}
    }
}
/*==================================
=            Header-nav            =
==================================*/
.header-top {
	color: $header_top_color;
	background-color: $header_top_bg;
	box-shadow: 3px 3px 5px rgba(0,0,0,0.26);
	@include media-breakpoint-down(sm) {
		display: none;
	}
	p, ul {
		margin-bottom: 0;
	}
	@include media-breakpoint-up(lg) {
		.row {
			height: 32px;
		}
		.widget {
			margin-left: 1rem;
		}
	}
	.widget {
		padding: 0 1rem;
		@include media-breakpoint-down(md) {
			width: 100%;
			margin-bottom: 0.5rem;
			&.widget_search {
			}
		}
		&.cc_social_widget {
			margin-left: auto;
			li {
				margin-left: 1rem;
			}
		}
	}
	.search-form {
    	position: relative;
    	.input-group {
		    .form-control {
		    	height: 26px;
	    	    border-color: white;
		    	padding-top: 0.2rem;
		    	padding-bottom: 0.2rem;
	    	    box-shadow: none;
		    }
    		button.submit {
    			cursor: pointer;
    			padding-top: 0;
    			padding-bottom: 0;
    			color: #a5a4a4;
    			background-color: white;
    			border-color: white;
			    box-shadow: none;
    		}
    	}
    }

}
.header-nav {
	.main-navigation {
		@include media-breakpoint-up(lg) {
			padding: 0;
		}
		.navbar-brand {
			margin-left: 1rem;
			@include media-breakpoint-down(sm) {
			    max-width: 140px;
		    	margin-left: 1rem;
			}
			@include media-breakpoint-up(lg) {
    			flex: 0 0 20%;
			    max-width: 20%;
			}
		}
		.primary-menu {
			li.nav-item {
				@include media-breakpoint-up(lg) {
					padding-left: 1.5rem;
					padding-right: 1.5rem;
				}
				a.nav-link {
					font-weight: bold;
					text-transform: uppercase;
					color: $header_nav_link_color;
					text-decoration: none;
					@include media-breakpoint-up(lg) {
						padding-left: 0;
						padding-right: 0;
					}
				}
				&.menu-item-object-language_switcher {
					padding: 0;
				}
			}
			> li.nav-item.active, li.nav-item:hover {
				a.nav-link {
					color: $header_nav_link_hover_color
				}
			}
			&.search-focused {
				li.nav-item {
					@include media-breakpoint-up(lg) {
						padding-left: 1rem;
						padding-right: 1rem;
					}
				}
			}
		}
		.search-form {
	    	position: relative;
	    	.input-group {
			    .form-control {
			    	color: transparent;
	    			border-color: transparent;
		    	    background-color: transparent;
		    	    max-width: 40px;
		    	    &::-webkit-input-placeholder {
		    	    	color: transparent;
		    	    }
		    	    &:focus {
		    	    	color: #333;
		    	    	max-width: initial;
	    	    	    border-color: #eee;
					    outline: 0;
					    border-radius:.25rem;
					    -webkit-box-shadow: 0 0 0 0.2rem rgba(255,255,255,.25);
					    box-shadow: 0 0 0 0.2rem rgba(255,255,255,.25);
		    	    }
			    }
	    		button.submit {
	    			color: #333;
	    			border-color: transparent;
		    	    background-color: transparent;
		    	    position: absolute;
		    	    z-index: 0;
		    	    right: 0;
	    		}
			    // .form-control:focus + .input-group-btn button.submit {
			    // 	z-index: 9;
			    // }
	    	}
		}
	}
	.toolbar {
		margin-bottom: 0;
		margin-left: auto;
		li {
			a {
				color: $secondary_color;
			}
		}
	}
	.header-nav-search {
		@include media-breakpoint-down(sm) {
			display: none;
		}

		.search-form {
			.input-group-addon {
				display: initial;
				background-color: #fff;
				position: absolute;
				z-index: 9;
				left: -10px;
			}
			.form-control {
				padding-left: 3rem;
				border-left: 0;
				&:focus {
					box-shadow: none;
					border-color: #ced4da;
				}
			}
		}
	}
}
@import "search-form";
/*=====  End of Header-nav  ======*/
