.breadcrumbs {
	padding: 0.5rem;
	a {
		color: #333;
	}
}

#layout {
	.above-content {
		@include media-breakpoint-up(lg) {
			.crellyslider .cs-navigation {
			    display: flex!important;
			    flex-wrap: wrap;
			    align-items: center;
			    justify-content: center;
			    .cs-slide-link {
			    	display: block;
				    width: 9px;
				    height: 9px;
				    margin-left: 5px;
				    margin-right: 5px;
				    opacity: 1;
				    &.cs-active {
					    width: 13px;
					    height: 13px;
					    background-color: $primary_color;
					}
				}
			}
		}
	}
	&.sidebar-left {
		@include media-breakpoint-up(lg) {
			.site-main {
				padding-left: 0;
			}
			.widget-area {
				padding-right: 30px;
			}
		}
	}
}
body:not(.home) {
	#layout .container {

	}
}