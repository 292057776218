.cc_photo_gallery_widget {
	background-color: #f7f7f7;
	.featured-photo {
		@include media-breakpoint-up(lg) {
			height: 100%;
		}
		@include media-breakpoint-down(sm) {
			padding: 0;
		}
		.photo {
			height: 100%;
		}
	}
	.other-photo {
		display: flex;
		flex-wrap: wrap;
		@include media-breakpoint-down(sm) {
			padding: 0;
		}
		.photo {
			padding: 0;
			@include media-breakpoint-up(lg) {
			    padding: 0 10px;
			    &:first-child, &:nth-child(2) {
			    	padding-top: 0;
			    	padding-bottom: 15px;
			    }
			    img {
		    	    width: 243px;
				    max-height: 243px;
				    object-fit: cover;
			    }
		    }
		}
	}
	.photo {
		@include media-breakpoint-down(sm) {
			margin-bottom: 1rem;
		}
	}
}