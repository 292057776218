
.article-wrap {
	height: 100%;
	.entry-content {
		padding: 1rem;
		font-size: 14px;
		h2.entry-title {
			font-size: 14px;
			a {
				color: #333;
				font-weight: bold;
			}
		}
		.entry-meta {
			a {
				font-size: 13px;
				color: #333;
			}
		}
		p {
			font-size: 14px;
		    max-height: 120px;
    		overflow: hidden;
		}
		.read-more-link {
			border: 0;
		    float: right;
			border-radius: 0;
			color: $primary_color;
			background-color: transparent;
		}
	}

}