.site-footer {
	background: $footer_bg;
	border-top: 4px solid $primary_color;
	.footer-top {
		background-color: #fff;
	}
	.footer-mid {
		padding: 1rem 0;
		.widget {
			margin-bottom: 1rem;
			.widget-title {
				color: $footer_color;
				font-size: 15px;
				text-transform: uppercase;
				padding-left: 0.5rem;
				margin-bottom: 1rem;
				border-left: 2px solid $primary_color;
			}
			p, a {
				color: $footer_color;
			}
			i {
				color: $primary_color;
			}
			ul.menu {
				@include list-unstyled;
				margin-bottom: 0;
				li {
					a {
						
					}
				}
			}
			&.cc_social_widget {
				text-align: center;
				a {
					background-color: $primary_color;
					display: inline-block;
					font-weight: $btn-font-weight;
					text-align: center;
					white-space: nowrap;
					vertical-align: middle;
					user-select: none;
					border: $input-btn-border-width solid transparent;
					@include button-size($input-btn-padding-y, $input-btn-padding-x, $font-size-base, $input-btn-line-height, $btn-border-radius);
					@include transition($btn-transition);

					// Share hover and focus styles
					@include hover-focus {
						text-decoration: none;
					}
					&:focus,
					&.focus {
						outline: 0;
						box-shadow: $input-btn-focus-box-shadow;
					}
					&.disabled,
					&:disabled {
						opacity: .65;
						@include box-shadow(none);
					}

					&:not([disabled]):not(.disabled):active,
					&:not([disabled]):not(.disabled).active {
						background-image: none;
						@include box-shadow($input-btn-focus-box-shadow, $btn-active-box-shadow);
					}
				}
			}
		}
		.footer-col-1 {
			.widget_text {
				text-align: justify;
				i {
					margin-right: 0.5rem;
				    width: 20px;
				}
			}
			
		}
		.footer-col-2, .footer-col-3, .footer-col-4 {
			@include media-breakpoint-up(lg) {
				padding-top: 1rem;
			}
		}
		.col-sm-12.single {
		    display: flex;
		    input {
			    border-radius: 0;
			}
		}
	}
	.footer-copyright {
		background-color: $footer_copyright_bg;
		.list-inline.menu {
			text-align: center;
			 .menu-item {
			    display: inline-block;
			    padding: 0.25rem 0;
			    a {
			    	padding: 0 1rem;
			    	color: $footer_color;
			    }
			    &:not(:first-child) a {
			    	border-left: 1px solid $footer_color;
			    }
			}
		}
		color: $footer_color;
		padding: 1rem 0;
	}
}
#scroll-top {
	color: #fff;
	cursor: pointer;
	right: 1rem;
	bottom: 1rem;
	background-color: $primary_color;
}