.cc_post_featured_widget {
	background-color: #f7f7f7;
	article {
		@include media-breakpoint-down(md) {
			margin-bottom: 1.5rem;
			.thumbnail {
				padding: 0;
			}
		}
		.entry-title {
			font-size: 15px;
			margin-top: 0.25rem;
			margin-left: 0.5rem;
			a {
				color: #333;
				font-weight: bold;
			}
		}
		ul.time {
			margin-bottom: 0.5rem;
			li {
				color: #acacac;
				font-size: 13px;
				&:first-child {
					margin-right: 1rem;
				}
			}
		}
		.entry-content {
			color: #666666;
			padding: 0 0.5rem;
			p {
				font-size: 14px;
			}
		}
		a.post-featured-viewmore {
			color: $primary_color;
		}
	}
}