/*----------  Base  ----------*/

$primary_color: #015ea7;
$secondary_color: #f48020;
$primary_dark_color: #04396e;

/*----------  top  ----------*/

$header_top_bg: $primary_color;
$header_top_color: white;
$header_top_link_hover_color: $primary_color;

/*----------  nav  ----------*/

$header_nav_bg: white;
$header_nav_link_color: #333;
$header_nav_link_hover_color: $secondary_color;

/*----------  Widget  ----------*/

$widget_title_color: #333;
$widget_title_bg: $primary_color;
$widget_title_bg_border: 1px solid #e5e5e5;

/*----------  Widget_Nav_Menu  ----------*/
$widget_nav_menu_color: $gray-800;
$widget_nav_menu_bg: transparent;
$widget_nav_menu_item_border: 1px solid #ddd;

$widget_nav_menu_color_hover: $secondary_color;
$widget_nav_menu_bg_hover: transparentize($primary_color, 0.4);

/*----------  Widget_Nav_Menu  ----------*/
$footer_bg: #181717;
$footer_color: #d2d2d2;
$footer_copyright_bg: #2f3742;