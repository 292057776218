.cc_post_grid_widget {
	.article-wrap {
		background-color: #f7f7f7;
		.entry-content {
			padding: 0.5rem;
		    border: 1px solid #e4e4e4;
		    font-size: 14px;
		    color: #646464;
		}
	}
}
.sidebar {
	.cc_post_grid_widget {
		.article-list-wrap {
			padding-top: 10px;
			padding-bottom: 10px;
			> .col-8 {
				padding-left: 10px;
				.entry-title {
					font-size: 15px;
				}
			}
		}
	}
}